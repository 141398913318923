import criteria from '~/composables/criteria/useCrossSellingApiParams.json';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { Schemas } from '#shopware';

export const useCrossSellings = () => {
    const { apiClient } = useShopwareContext();

    const crossSellings = useState<Schemas['CrossSellingElementCollection']>('crossSellings', () => []);

    const loadCrossSellings = async (productId: string): Promise<Schemas['CrossSellingElementCollection']> => {
        try {
            crossSellings.value = await apiClient.invoke(
                'readProductCrossSellings post /product/{productId}/cross-selling',
                {
                    productId,
                    ...criteria,
                },
            );
        } catch (e) {
            Logger.captureException(e);
        }

        return crossSellings.value;
    };

    const accessories = computed<Array<Schemas['Product']> | undefined>(() => {
        return crossSellings.value?.find((item) => getTranslatedProperty(item.crossSelling, 'name') === 'accessories')
            ?.products;
    });

    const spareParts = computed<Array<Schemas['Product']> | undefined>(() => {
        return crossSellings.value?.find((item) => getTranslatedProperty(item.crossSelling, 'name') === 'spareparts')
            ?.products;
    });

    return {
        loadCrossSellings,
        crossSellings,
        accessories,
        spareParts,
    };
};
